import * as React from 'react';
import styled from 'styled-components';
import { media } from '../layouts/config';

export const Article = (props: React.HTMLAttributes<HTMLElement>) => (
  <StyledArticle {...props} />
);

const StyledArticle = styled.article`
  .content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    p,
    ul {
      width: 95%;
      margin-left: 2.5%;
      margin-right: 2.5%;
    }

    ${media.md`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    p,
    ul {
      width: 70%;
      margin-left: 15%;
      margin-right: 15%;
    }
  `};

    ${media.lg`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    p,
    ul {
      width: 60%;
      margin-left: 20%;
      margin-right: 20%;
    }
  `};

    ${media.hd`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    p,
    ul {
      width: 50%;
      margin-left: 25%;
      margin-right: 25%;
    }
  `};
  }
`;
