import React from 'react';
import styled from 'styled-components';
import { color, fontSize } from '../../layouts/config';
import { Paragraph } from '../../layouts/typography';
import './foldableBooklet.css';

interface State {
  stage: number;
  reverse: boolean;
}

export class FoldableBooklet extends React.PureComponent<{}, State> {
  state = { stage: 0, reverse: false };

  stages = [
    'paper firstStep',
    'paper firstStep fold',
    'paper firstStep foldQuart',
    'paper firstStep foldQuart',
    'paper firstStep foldQuart',
    'paper firstStep foldQuart'
  ];

  stages2 = [
    'paper secondStep',
    'paper secondStep',
    'paper secondStep',
    'paper secondStep fold',
    'paper secondStep foldClose',
    'paper secondStep foldRotate'
  ];

  resetClasses = () => {
    const paper: HTMLDivElement | null = document.querySelector('.firstStep');
    const paper2: HTMLDivElement | null = document.querySelector('.secondStep');

    if (paper && paper2) {
      paper.style.display = 'flex';
      paper2.style.display = 'none';
    }
  };

  nextStage = () => {
    this.setState(
      {
        stage:
          this.state.stage === this.stages.length - 1
            ? 0
            : this.state.stage + 1,
        reverse:
          (this.state.reverse && this.state.stage >= 0) ||
          (!this.state.reverse && this.state.stage === this.stages.length - 2)
      },
      () => {
        if (this.state.stage === 0) {
          this.resetClasses();
        }
      }
    );
  };

  componentDidMount() {
    const paper: HTMLDivElement | null = document.querySelector('.firstStep');
    const paper2: HTMLDivElement | null = document.querySelector('.secondStep');

    if (paper && paper2) {
      paper.addEventListener('transitionend', () => {
        if (paper.className === 'paper firstStep foldQuart') {
          paper.style.display = 'none';
          paper2.style.display = 'flex';
        }
      });

      paper2.addEventListener('transitionend', () => {
        if (paper2.className === 'paper secondStep') {
          paper.style.display = 'flex';
          paper2.style.display = 'none';
        }
      });
    }
  }

  render() {
    return (
      <Container onClick={this.nextStage}>
        <div className={this.stages[this.state.stage]}>
          <div className="cell q-1 top left" />
          <div className="cell q-2 top right" />
          <div className="cell q-3 bottom left" />
          <div className="cell q-4 bottom right" />
        </div>

        <div className={this.stages2[this.state.stage]}>
          <div className="cell q-1 top left">
            <div className="backFace" />
          </div>
          <div className="cell q-2 top right">
            <div className="backFace" />
          </div>
          <div className="cell q-3 bottom left">
            <div className="backFace" />
          </div>
          <div className="cell q-4 bottom right">
            <div className="backFace" />
          </div>
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  align-items: center;
  background-color: ${color.notelight};
  cursor: pointer;
  display: flex;
  height: 90vh;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
`;

export const Instructions = styled(Paragraph)`
  color: ${color.note};
  font-variant: small-caps;
  font-weight: bold;
  text-align: center;
  font-size: ${fontSize.h6};
  margin: 2rem auto 1rem auto;
  text-transform: lowercase;
`;
