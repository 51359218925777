import { graphql, StaticQuery, withPrefix } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';
import { Copyright } from './components/copyright';
import { Header } from './header';
import {
  breakpoints,
  color,
  easeOut,
  media,
  sans,
  serif,
  unitPercent
} from './layouts/config';

interface Props {
  children: React.ReactNode;
  data?: {};
}

export default class Layout extends React.Component<Props> {
  shouldComponentUpdate() {
    return false;
  }

  // @ts-ignore
  renderer = (data: StaticQueryProps['RenderCallback']) => (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            content:
              'Work & Play of Alex Ketch, designer & developer in Montréal.'
          },
          {
            name: 'keywords',
            content:
              'Alex Ketch, portfolio, graphic design, developer, CalArts, poster, branding, front-end, javascript, typescript, engineer'
          }
        ]}
      >
        <html lang="en" />
        <script
          src={withPrefix('/assets/fonts/webfontsKit.js')}
          type="text/javascript"
          async={true}
        />
        {process.env.NODE_ENV !== 'development' && (
          <script
            src={withPrefix('/heap.js')}
            type="text/javascript"
            async={true}
          />
        )}
      </Helmet>
      <GlobalFonts />
      <GlobalStyles />

      <Header />

      <Container>{this.props.children}</Container>

      <Copyright />
    </>
  );

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={this.renderer}
      />
    );
  }
}

// =============================================================================

const Container = styled.div`
  margin: 0 auto;
  transition: padding ${easeOut};

  .gatsby-resp-image-link {
    cursor: url('${withPrefix('cursors/zoom.svg')}') 24 20, zoom-in;
  }

  article > .gatsby-resp-image-link,
  article > div > .gatsby-resp-image-link {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    p,
    ul {
      padding-left: ${unitPercent.md};
      padding-right: ${unitPercent.md};
    }
  }

  ${media.md`
    padding: 0 ${unitPercent.md};
  `};

  ${media.xl`
    padding-top: ${unitPercent.md};
  `};

  .gatsby-highlight {
    font-size: 0.825em;
    margin: 2rem 0;

    pre {
      border: 1px solid ${color.brand};
      border-radius: 5px;
      line-height: 1.7;

      .line-numbers-rows {
        padding: 1em 0 1em 1em;
      }
    }
  }

  pre {
    font-size: 14px;
    border: 1px solid ${color.brand};
    border-radius: 5px;
    line-height: 1.7;
    overflow: auto;
  }
`;

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-display: optional;
    font-family: "CiraSerif";
    src: url('/assets/fonts/Cira/37DFB3_1_0-2.eot');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-display: optional;
    font-family: "CiraSerif";
    src: url("/assets/fonts/Cira/37DFB3_0_0.eot");
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-display: optional;
    font-family: 'TT Firs Neue';
    src: url('/assets/fonts/TTFirs/tt-firs-neue-regular.eot');
    src: url('/assets/fonts/TTFirs/tt-firs-neue-regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/TTFirs/tt-firs-neue-regular.woff2') format('woff2'),
        url('/assets/fonts/TTFirs/tt-firs-neue-regular.woff') format('woff'),
        url('/assets/fonts/TTFirs/tt-firs-neue-regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-display: optional;
    font-family: 'TT Firs Neue';
    src: url('/assets/fonts/TTFirs/tt-firs-neue-italic.eot');
    src: url('/assets/fonts/TTFirs/tt-firs-neue-italic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/TTFirs/tt-firs-neue-italic.woff2') format('woff2'),
         url('/assets/fonts/TTFirs/tt-firs-neue-italic.woff') format('woff'),
         url('/assets/fonts/TTFirs/tt-firs-neue-italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

    @font-face {
    font-display: optional;
    font-family: 'TT Firs Neue';
    src: url('/assets/fonts/TTFirs/tt-firs-neue-medium.eot');
    src: url('/assets/fonts/TTFirs/tt-firs-neue-medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/TTFirs/tt-firs-neue-medium.woff2') format('woff2'),
        url('/assets/fonts/TTFirs/tt-firs-neue-medium.woff') format('woff'),
        url('/assets/fonts/TTFirs/tt-firs-neue-medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-display: optional;
    font-family: 'TT Firs Neue';
    src: url('/assets/fonts/TTFirs/tt-firs-neue-medium-italic.eot');
    src: url('/assets/fonts/TTFirs/tt-firs-neue-medium-italic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/TTFirs/tt-firs-neue-medium-italic.woff2') format('woff2'),
         url('/assets/fonts/TTFirs/tt-firs-neue-medium-italic.woff') format('woff'),
         url('/assets/fonts/TTFirs/tt-firs-neue-medium-italic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-display: optional;
    font-family: 'TT Firs Neue';
    src: url('/assets/fonts/TTFirs/tt-firs-neue-demibold.eot');
    src: url('/assets/fonts/TTFirs/tt-firs-neue-demibold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/TTFirs/tt-firs-neue-demibold.woff2') format('woff2'),
        url('/assets/fonts/TTFirs/tt-firs-neue-demibold.woff') format('woff'),
        url('/assets/fonts/TTFirs/tt-firs-neue-demibold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-display: optional;
    font-family: 'TT Firs Neue';
    src: url('/assets/fonts/TTFirs/tt-firs-neue-demibold-italic.eot');
    src: url('/assets/fonts/TTFirs/tt-firs-neue-demibold-italic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/TTFirs/tt-firs-neue-demibold-italic.woff2') format('woff2'),
         url('/assets/fonts/TTFirs/tt-firs-neue-demibold-italic.woff') format('woff'),
         url('/assets/fonts/TTFirs/tt-firs-neue-demibold-italic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
`;

export const GlobalStyles = createGlobalStyle`
  html, body {
    background-color: ${color.stock};
    box-sizing: border-box;
    font-family: ${sans};
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-feature-settings: "liga", "dlig" off, "calt" off,"onum","swsh" off ,"subs" off,"sups" off, "kern";

    * {
      box-sizing: border-box;
    }
  }

  ::selection {
   background: ${color.key};
   color: ${color.stock};
  }

  h1, h2, h3, h4, h5, h6 {
  font-family: ${serif};
  }

  ul,
  p {
    line-height: 1.5;
  }

  b, strong {
    font-weight: 600;
  }

  picture {
    display: block;
  }

  .gatsby-image-wrapper {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.08);
  }

  ${media.md`
    html, body {
      font-size: 17px;
    }
  `};
`;
