import { graphql, Link } from 'gatsby';
import GatImg from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';
import { color, easeOut, fontSize, media } from '../components/layouts/config';
import { H1, Paragraph } from '../components/layouts/typography';

interface Page {
  node: {
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      featuredImage: {
        publicURL: string;
      };
    };
    excerpt: string;
  };
}

interface Data {
  allMdx: {
    edges: Page[];
  };
}

const PlayPage = (props: { data: Data }) => (
  <Wrapper>
    {props.data.allMdx.edges.map((p, i) => (
      <Article key={i}>
        <WorkLink to={p.node.fields.slug}>
          <IndexH1>{p.node.frontmatter.title}</IndexH1>

          {p.node.frontmatter.featuredImage ? (
            <GatImg
              // @ts-ignore
              fluid={p.node.frontmatter.featuredImage.childImageSharp.fluid}
              alt={p.node.frontmatter.title}
            />
          ) : (
            <IndexExcerpt>{p.node.excerpt}</IndexExcerpt>
          )}
        </WorkLink>
      </Article>
    ))}
  </Wrapper>
);

export default PlayPage;

export const query = graphql`
  query Play {
    allMdx(
      filter: {
        fields: { category: { eq: "play" } }
        frontmatter: { draft: { ne: true } }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          code {
            scope
          }
          fields {
            id
            slug
          }
          frontmatter {
            title
            layout
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const WorkLink = styled(Link)`
  display: block;
  text-decoration: none;
  transition: all ${easeOut};
  position: relative;

  &:hover {
    box-shadow: 10px 10px 10px ${color.brand};
  }
`;

const Article = styled.article`
  margin-bottom: 1.25rem;
  overflow: hidden;
  margin-bottom: 2.125rem;
  transition: width ${easeOut};
  width: 100%;

  .gatsby-image-wrapper {
    border-radius: 3px;
    overflow: hidden;
    display: block;
  }

  a,
  a:href,
  a:visited {
    display: block;
    border-radius: 3px;
    overflow: hidden;
    color: ${color.key};
  }

  ${media.md`
    margin-bottom: 3rem;
    padding: 1.25rem;
    width: 50%;

    &:first-of-type {
      width: 50%;
    }

    &:nth-child(2n+3) {
      padding-left: 0;
    }

    &:nth-child(2n+4) {
      padding-right: 0;
    }
  `};

  ${media.lg`
    &:nth-of-type(3n+1),
    &:nth-of-type(3n+2),
    &:nth-of-type(3n+3) {
      padding: 1.25rem;
      width: 33.33333%;
    }

    &:first-of-type,
    &:nth-of-type(2) {
      width: 50%;
    }
  `};

  img {
    max-width: 100%;
    display: block;
  }
`;

const IndexH1 = styled(H1)`
  background-color: ${color.brand};
  bottom: 0;
  color: ${color.stock};
  display: inline-block;
  font-size: ${fontSize.h6};
  margin: 0;
  padding: 0.25em 0.5em 0.125em;
  position: absolute;
  right: 0;
  text-align: right;
  width: auto;
  z-index: 2;
`;

const IndexExcerpt = styled(Paragraph)`
  color: ${color.key};
  padding-bottom: 2.5rem;
  width: 100%;
`;
