import { Link } from '@reach/router';
import * as React from 'react';
import { H1, Paragraph } from '../components/layouts/typography';

const NotFoundPage = () => (
  <>
    <H1>Page not found 😨</H1>
    <Paragraph>
      You might've followed an old link, try the <Link to="/">home page</Link>{' '}
      instead?
    </Paragraph>
    <Paragraph>
      <img
        src="//media.giphy.com/media/JIKPuz6HSB7t6/giphy.gif"
        alt="Oh noooo!"
      />
    </Paragraph>
  </>
);

export default NotFoundPage;
