import { graphql, Link } from 'gatsby';
import GatImg from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';
import { easeOut, media } from '../components/layouts/config';
import { FeaturedTitle } from '../components/title/title';

interface Page {
  node: {
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      featuredImage: {
        publicURL: string;
      };
    };
  };
}

interface Data {
  allMdx: {
    edges: Page[];
  };
}

const IndexPage = (props: { data: Data }) => (
  <>
    {props.data.allMdx.edges.map((p, i) => (
      <WorkLink to={p.node.fields.slug} key={i}>
        <Article>
          <TitleWrapper>
            <ArticleTitle hoverSyle={true}>
              {p.node.frontmatter.title}
            </ArticleTitle>
          </TitleWrapper>

          <GatImg
            // @ts-ignore
            fluid={p.node.frontmatter.featuredImage.childImageSharp.fluid}
            alt={p.node.frontmatter.title}
          />
        </Article>
      </WorkLink>
    ))}
  </>
);

export default IndexPage;

export const query = graphql`
  query FeaturedWork {
    allMdx(
      filter: { fields: { category: { eq: "work" } } }
      sort: { order: ASC, fields: frontmatter___date }
    ) {
      edges {
        node {
          code {
            scope
          }
          fields {
            id
            slug
          }
          frontmatter {
            title
            layout
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 2880) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Article = styled.article`
  border-radius: 3px;
  max-height: calc(100vh - 6.5vw - 5rem);
  overflow: hidden;
  position: relative;
  transition: box-shadow ${easeOut};

  .gatsby-image-wrapper {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    overflow: hidden;
    display: block;
    transition: transform ${easeOut};
    transform: perspective(250px) translateZ(3px);
  }

  &:hover {
    .gatsby-image-wrapper {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.08);
      transform: perspective(250px) translateZ(0);
    }
  }

  img {
    max-width: 100%;
  }
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 90%;
  z-index: 2;
`;

const ArticleTitle = styled(FeaturedTitle)`
  margin: 0 auto;
`;

const WorkLink = styled(Link)`
  display: block;
  border-radius: 3px;
  overflow: hidden;
  text-decoration: none;

  margin-bottom: 2.125rem;

  ${media.md`
    margin-bottom: 3rem;
  `};

  &:hover {
    h1 {
      transform: scale(1.0625);
    }
  }
`;
