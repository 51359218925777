import { Link } from '@reach/router';
import { withPrefix } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Article } from '../components/article/article';
import {
  color,
  font,
  fontSize,
  media,
  sans
} from '../components/layouts/config';
import { Anchor, H2, Paragraph, UL } from '../components/layouts/typography';
import { FeaturedTitle } from '../components/title/title';

export default class About extends React.PureComponent<{}> {
  render() {
    return (
      <AboutArticle>
        <Helmet
          meta={[
            {
              name: 'robots',
              content: 'noindex'
            }
          ]}
        />
        <Profile>
          <Name>Alex Ketch</Name>
          <TagLine>Designer &amp; Developer in Montréal</TagLine>
          <Contact>
            <Anchor href="mailto:alex@ketch.me">alex@ketch.me</Anchor>
            &nbsp;·&nbsp;
            <Anchor href="tel:+17789382490">+1 (778) 938-2489</Anchor>
          </Contact>
        </Profile>

        <Bio>
          <section>
            <Paragraph>
              As a designer and developer with over six years of professional
              experience, I have a keen eye for aesthetics and usability, as
              well as breadth of technical knowledge. I strive to bring
              cohesive, people focused perspective to projects, with the ability
              for swift iterations rooted in testing and feedback to deliver a
              superior product.
            </Paragraph>

            <Paragraph>
              Having worked in both independent freelance and full-time
              capacity, I take professional pride in my organizational skills,
              fluid integration with teams, and ease of collaboration. Through
              working with remote clients and teammates I have developed
              excellent communication skills, both in person and digitally.
            </Paragraph>

            <Paragraph>
              When not working, I enjoy rock climbing, pursuing Functional
              Programming further in Haskell, and playing chess with friends.
            </Paragraph>
          </section>

          <section>
            <H2>Education</H2>

            <UL>
              <li>
                <strong>BFA Graphic Design</strong> @ CalArts, US
              </li>
              <li>
                <strong>BTEC National Diploma</strong> in Graphic Design @
                Croydon College, UK
              </li>
            </UL>
          </section>
        </Bio>

        <Skills>
          <section>
            <H2>Skills</H2>

            <UL>
              <li>JavaScript/TypeScript</li>
              <li>React, Redux, D3, Node</li>
              <li>HTML/CSS</li>
              <li>UX/UI design</li>
              <li>Docker deployment workflows</li>
              <li>Versed in Sketch as well as all Adobe CS applications</li>
              <li>
                Project management, including writing project briefs and
                outsourcing work to freelancers
              </li>
              <li>
                Digital print production, press checks, screenprinting, and
                letterpressing
              </li>
              <li>
                Fluent in Georgian, English, and Russian. Currently studying
                French
              </li>
            </UL>
          </section>

          <section>
            <H2>Clients Include</H2>

            <UL>
              <li>
                Currently at{' '}
                <strong>
                  <Anchor href="https://stenci.la" target="_blank">
                    Stencila
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor href="https://datalogue.io" target="_blank">
                    Datalogue
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor href="https://trumaker.com" target="_blank">
                    Trumaker
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor href="https://bandzoogle.com" target="_blank">
                    Bandzoogle
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor href="https://unito.io" target="_blank">
                    Unito
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor href="https://blog.cordblood.com" target="_blank">
                    Cord Blood Registry
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor
                    as={Link}
                    to="/play/2015/01-deepDeepBodyGl!tter/deepDeepBodyGl!tter/"
                  >
                    Machine Project
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor href="https://bgcc.org.uk" target="_blank">
                    British Georgian Chamber of Commerce
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>
                  <Anchor href="https://smogdesign.com" target="_blank">
                    SMOG Design, Inc.
                  </Anchor>
                </strong>
              </li>
              <li>
                <strong>and others</strong>
              </li>
            </UL>
          </section>

          <Paragraph>
            <em>
              <Anchor href="/Alex-Ketch-Resume.pdf" target="_blank">
                Download résumé
              </Anchor>{' '}
              or{' '}
              <Anchor
                href="https://www.linkedin.com/in/alexketch/"
                target="_blank"
              >
                view LinkedIn
              </Anchor>
            </em>
          </Paragraph>
        </Skills>
      </AboutArticle>
    );
  }
}

// =============================================================================

const AboutArticle = styled(Article)`
  ${media.lg`
    display: flex;
    justify-content: space-between;
    padding-left: 3.25%;
    width: 100%;
    flex-flow: row-reverse;
    flex-wrap: wrap;
  `};

  ${media.hd`
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    max-width: 1440px;
  `};
`;

const Profile = styled.section`
  text-align: right;
  width: 100%;

  h1,
  h2,
  p {
    text-align: right;
    padding-right: 6.5%;
    width: 100%;
  }

  ${media.lg`
    h1,
    h2,
    p {
      text-align: right;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
      padding-left: 50%;
      width: 100%;
    };
  `};
`;

const Name = styled(FeaturedTitle)`
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;

  span {
    display: inline-block;
    max-width: 600px;
    width: 100%;
  }

  ${media.lg`
    font-size: 3.5rem;
    padding-left: calc(50% + 3.25vw) !important;
  `};
`;

const TagLine = styled(H2)`
  border: none;
  color: ${color.brandD};
  font-family: ${sans};
  font-size: ${fontSize.h6};
  font-variant: normal;
  font-weight: ${font.bold};
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  max-width: none;
  text-transform: none;

  ${media.lg`
    font-size: ${fontSize.h5};
  `};
`;

const Contact = styled(Paragraph)`
  color: ${color.note};
  font-weight: ${font.bold};
  line-height: 1;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  max-width: none;
  padding-bottom: 0;
  padding-top: 0;

  a:link,
  a:visited {
    background: transparent;
    cursor: url('${withPrefix('cursors/mail.svg')}') 24 8, pointer;
  }

  a:hover,
  a:visited:hover {
    color: ${color.key};
  }

  a:link:last-of-type {
    cursor: url('${withPrefix('cursors/phone.svg')}') 14 17, pointer;
  }
`;

const Bio = styled.div`
  ${media.lg`
    padding-left: 3.25vw;
    width: 50%;
    max-width: calc(600px + 3.25vw);

    p,
    ul,
    h2 {
      width: 100%;
    }
  `};
`;

const Skills = styled(Bio)`
  ${media.lg`
    margin-top: -.75em;
    padding-right: 3.25vw;
  `};
`;
