import { LinkGetProps } from '@reach/router';
import { Link } from 'gatsby';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/themes/prism-tomorrow.css';
import * as React from 'react';
import styled from 'styled-components';
import { color, easeOut, font, fontSize, media, serif } from './layouts/config';

interface Props {}

const isPartiallyActive = ({
  isPartiallyCurrent,
  href,
  location
}: LinkGetProps) =>
  (href === '/' && location.pathname === '/') ||
  (href !== '/' && isPartiallyCurrent) ||
  (href === '/' && location.pathname.includes('/work/'))
    ? { 'data-active': true }
    : {};

export class Header extends React.Component<Props> {
  public shouldComponentUpdate() {
    return false;
  }

  public render() {
    return (
      <Head>
        <Nav>
          <NavLink to="/" getProps={isPartiallyActive}>
            Work
          </NavLink>
          &nbsp;&amp;&nbsp;
          <NavLink to="/play" getProps={isPartiallyActive}>
            Play
          </NavLink>
          &nbsp;of&nbsp;
          <NavLink to="/about">Alex Ketch</NavLink>
        </Nav>
      </Head>
    );
  }
}

// =============================================================================

const Head = styled.header`
  margin: 1.5rem 0;
  font-size: ${fontSize.h5};
  font-family: ${serif};
  transition: font-size ${easeOut};

  ${media.md`
    font-size: ${fontSize.h4}
  `};

  ${media.xl`
    position: fixed;
    left: 3.75vw;
    bottom: 0;
    width: 100vh;
    margin: 0;
    transform-origin: bottom left;
    transform: rotate(-90deg);
  `};
`;

const Nav = styled.nav`
  color: ${color.key};
  font-weight: ${font.bold};
  margin: 0 auto;
  text-align: center;
  line-height: 1;
`;

const NavLink = styled(Link).attrs({
  activeClassName: 'current'
})`
  color: ${color.key};
  font-weight: ${font.bold};
  text-decoration: none;
  position: relative;
  margin: 0 0.125em;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: ${color.note};
    position: absolute;
    bottom: -1px;
    left: 0;
    transition: height ${easeOut}, color ${easeOut};
  }

  &[data-active],
  &.current {
    &::before {
      height: 2px;
    }

    &::before,
    &:hover::before {
      background-color: ${color.key};
    }
  }

  &:hover::before {
    background-color: ${color.accent};
    height: 2px;
  }
`;
