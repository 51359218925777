import { Link, navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { color, easeOut, fontSize, media } from '../../layouts/config';

interface Entry {
  frontmatter: {
    title: string;
  };
  fields: {
    slug: string;
  };
}

interface Props {
  next?: Entry;
  previous?: Entry;
}

export class Wayfinder extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  navTo = (e: KeyboardEvent) => {
    if (e.key === 'ArrowRight' && this.props.next) {
      navigate(this.props.next.fields.slug);
    } else if (e.key === 'ArrowLeft' && this.props.previous) {
      navigate(this.props.previous.fields.slug);
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.navTo);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.navTo);
  }

  render() {
    const { next, previous } = this.props;

    return (
      <>
        {previous && (
          <Arrow
            to={previous.fields.slug}
            title={`View previous entry - ${previous.frontmatter.title}`}
          >
            ↤
          </Arrow>
        )}
        {next && (
          <Next
            to={next.fields.slug}
            title={`View next entry - ${next.frontmatter.title}`}
          >
            ↦
          </Next>
        )}
      </>
    );
  }
}

const Arrow = styled(Link)`
  display: none;

  ${media.xl`
    bottom: 2rem;
    display: inline;
    color: ${color.key};
    font-size: ${fontSize.h4};
    font-weight: bold;
    height: 1em;
    left: 1.875vw;
    position: fixed;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    padding-left: 0;
    transition: left ${easeOut};

    &:hover {
      left: calc(1.875vw - 0.25rem);
    }
  `};
`;

const Next = styled(Arrow)`
  top: 1.5rem;
  bottom: auto;

  &:hover {
    left: calc(1.875vw + 0.25rem);
  }
`;
