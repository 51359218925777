import { css } from 'styled-components';

export const color = {
  accent: '#CEA33F',
  accentD: '#8a7003',
  brand: '#56CCAF',
  brandD: '#54CCAF',
  brandLight: '#50E3C2',
  key: '#121212',
  note: '#969696',
  notelight: '#DEDEDE',
  notemid: '#CCC',
  stock: '#FAFAFA'
};

export const sans = `"TT Firs Neue", Archia, Soleil, sans-serif`;
export const serif = `CiraSerif, "Cira Serif", "Didot", "Didot lt std", "Hoefler Text", "Garamond", "Times New Roman", serif`;

export const fontSize = {
  hxl: '7.5rem', // 120px
  hlg: '5.4117647059rem', // 92px
  h1: '4.5rem', // 72px
  h25: '3.2941176471rem', // 56px
  h2: '2.25rem', // 56px
  h3: '2rem', // 38px
  h4: '1.5rem', // 24px
  h5: '1.25rem', // 20px
  h6: '1rem', // 16px
  h7: '.875rem' // 14px
};

export const fontSizeR = {
  h1: css``
};

export const font = {
  xlight: 100,
  bold: 900
};

export const easeOut0 = '100ms ease-in-out';
export const easeOut = '150ms ease-out';

// =============================================================================

export const breakpoints = {
  base: null,
  xs: '350px',
  sm: '555px',
  md: '760px',
  lg: '1050px',
  xl: '1350px',
  hd: '1600px',
  ultra: '1850px'
};

type Sizes = keyof typeof breakpoints;

type R = Record<
  keyof typeof breakpoints,
  (l: TemplateStringsArray, ...p: any[]) => string
>;

const keys = Object.keys(breakpoints) as Sizes[];

// Iterate through the sizes and create a media template
// https://www.styled-components.com/docs/advanced#media-templates
// https://stackoverflow.com/a/49398439
export const media = keys.reduce(
  (queries: R, size: Sizes) => {
    // @ts-ignore
    queries[size] = (
      literals: TemplateStringsArray,
      ...placeholders: any[]
    ) => css`
      @media screen and (min-width: ${breakpoints[size]}) {
        ${css(literals, ...placeholders)};
      }
    `;
    return queries;
  },
  {} as R
);

// =============================================================================

export const unitPercent = {
  sm: '5%',
  md: '6.5%',
  lg: '10%'
};

export const unit = {
  sm: '3rem',
  md: '6.5rem',
  lg: '10%'
};

// =============================================================================

export const margins = {};
