import React from 'react';
import styled, { css } from 'styled-components';
import { color, easeOut, fontSize, media, sans, serif } from './config';

// =============================================================================

export const typeWidths = css`
  width: 100%;
  max-width: 600px;

  ${media.lg`
    width: 50%;
  `};
`;

// =============================================================================

export const Anchor = (props: React.HtmlHTMLAttributes<HTMLAnchorElement>) => (
  <A {...props} />
);

const A = styled.a`
  color: inherit;
  transition: all ${easeOut};
  text-decoration: none;
  background: linear-gradient(
    to right,
    ${color.accent} 0%,
    ${color.accentD} 100%
  );
  background-size: 100% 1px;
  background-repeat: repeat-x;
  background-position: 0 calc(1em + 2px);

  &:hover {
    text-decoration: none;
    background: linear-gradient(
      to right,
      ${color.brand} 0%,
      ${color.brandD} 100%
    );
    background-size: 100% 1px;
    background-repeat: repeat-x;
    background-position: 0 calc(1em + 4px);
  }
`;

// =============================================================================

export const Paragraph = (
  props: React.HtmlHTMLAttributes<HTMLParagraphElement>
) => <P {...props} />;

const P = styled.p`
  font-family: ${sans};
  margin: 1em auto;
  font-weight: 400;
  ${typeWidths};

  .gatsby-resp-image-wrapper {
    border: 1px solid ${color.notelight};
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.08);
    margin-left: 1rem;
    margin-right: 1rem;
    overflow: hidden;
    width: auto;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  a {
    color: inherit;
    transition: all ${easeOut};
    text-decoration: none;
    background: linear-gradient(
      to right,
      ${color.accent} 0%,
      ${color.accentD} 100%
    );
    background-size: 100% 1px;
    background-repeat: repeat-x;
    background-position: 0 calc(1em + 2px);

    &:hover {
      text-decoration: none;
      background: linear-gradient(
        to right,
        ${color.brand} 0%,
        ${color.brandD} 100%
      );
      background-size: 100% 1px;
      background-repeat: repeat-x;
      background-position: 0 calc(1em + 4px);
    }
  }

  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 2rem;
  }
`;

// =============================================================================

export const H1 = (props: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
  <Heading1 {...props} />
);

const Heading1 = styled.h1`
  line-height: 1;
  font-family: ${serif};
  margin: 3rem auto 1em auto;
  ${typeWidths};
  width: 87%;
`;

// =============================================================================

export const H2 = (props: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
  <Heading2 as="h2" {...props} />
);

const Heading2 = styled(Heading1)`
  border-bottom: 2px solid ${color.brand};
  font-size: 1.375rem;
  font-variant: small-caps;
  letter-spacing: 1px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-transform: lowercase;
`;

// =============================================================================

export const H3 = (props: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
  <Heading3 as="h3" {...props} />
);

const Heading3 = styled(Heading1)`
  border-bottom: 1px solid ${color.note};
  font-size: 1rem;
`;

// =============================================================================

export const H4 = (props: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
  <Heading4 as="h4" {...props} />
);

export const HeadingFour = H4;

const Heading4 = styled(Heading1)`
  font-family: ${sans};
  color: ${color.note};
  font-variant: small-caps;
  font-weight: bold;
  text-align: center;
  font-size: ${fontSize.h6};
  margin: 2rem auto 1rem auto;
  text-transform: lowercase;
`;

// =============================================================================

export const H5 = (props: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
  <Heading5 as="h5" {...props} />
);

const Heading5 = styled(Heading1)``;

// =============================================================================

export const H6 = (props: React.HtmlHTMLAttributes<HTMLHeadingElement>) => (
  <Heading6 as="h6" {...props} />
);

const Heading6 = styled(Heading1)``;

// =============================================================================

export const Quote = (props: React.HtmlHTMLAttributes<HTMLQuoteElement>) => (
  <Q {...props} />
);

const Q = styled.blockquote`
  margin: 0;
  width: 100%;

  & > p {
    display: block;
    font-family: ${serif};
    font-size: ${fontSize.h4};
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
    margin: 2rem auto;
    text-align: right;
    width: 100%;

    ${media.md`
      font-size: ${fontSize.h3};
      width: 75%;
      margin-right: 25%;
    `};

    ${media.lg`
      margin-left: 12.5%;
      font-size: ${fontSize.h2};
      width: 62.5%;
    `};

    ${media.hd`
      margin-left: 25%;
      margin-right: 25%;
      width: 50%;
    `};

    a:link {
      color: inherit;
      transition: all ${easeOut};
      text-decoration: none;
      background: linear-gradient(
        to right,
        ${color.accent} 0%,
        ${color.accentD} 100%
      );
      background-size: 100% 1px;
      background-repeat: repeat-x;
      background-position: 0 calc(1em - 4px);

      &:hover {
        text-decoration: none;
        background: linear-gradient(
          to right,
          ${color.brand} 0%,
          ${color.brandD} 100%
        );
        background-size: 100% 1px;
        background-repeat: repeat-x;
        background-position: 0 calc(1em - 2px);
      }
    }
  }
`;

// =============================================================================

export const UL = (props: React.HtmlHTMLAttributes<HTMLUListElement>) => (
  <UList {...props} />
);

const UList = styled.ul`
  padding: 0;
  ${typeWidths};
  margin: 0 auto;
  list-style-position: inside;
  list-style-type: circle;

  ${media.md`
    list-style-position: outside;
  `};

  li {
    margin-bottom: 0.25rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

// =============================================================================

export const typography = {
  a: (props: React.HTMLAttributes<HTMLAnchorElement>) => <Anchor {...props} />,
  blockquote: (props: React.HTMLAttributes<HTMLQuoteElement>) => (
    <Quote {...props} />
  ),
  h1: (props: React.HTMLAttributes<HTMLHeadingElement>) => <H1 {...props} />,
  h2: (props: React.HTMLAttributes<HTMLHeadingElement>) => <H2 {...props} />,
  h3: (props: React.HTMLAttributes<HTMLHeadingElement>) => <H3 {...props} />,
  h4: (props: React.HTMLAttributes<HTMLHeadingElement>) => <H4 {...props} />,
  h5: (props: React.HTMLAttributes<HTMLHeadingElement>) => <H5 {...props} />,
  h6: (props: React.HTMLAttributes<HTMLHeadingElement>) => <H6 {...props} />,
  p: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
    <Paragraph {...props} />
  ),
  ul: (props: React.HTMLAttributes<HTMLUListElement>) => <UL {...props} />
};
