import React from 'react';
import styled from 'styled-components';
import { color } from './config';

export const HR = (props: React.HtmlHTMLAttributes<HTMLHRElement>) => (
  <H {...props} />
);

const H = styled.hr`
  border: none;
  border-top: 1px solid ${color.notelight};
  color: ${color.notemid};
  height: 1px;
  overflow: visible;
  margin: 3rem auto;
  padding: 0;
  text-align: center;
  width: 50%;
  max-width: 600px;

  &:after {
    background-color: ${color.stock};
    content: '❈';
    display: inline-block;
    font-size: 1.5em;
    padding: 0 0.25em;
    position: relative;
    top: -0.625em;
  }
`;

export const ornaments = {
  hr: (props: React.HTMLAttributes<HTMLHRElement>) => <HR {...props} />
};
