import React from 'react';
import styled from 'styled-components';
import { color } from '../layouts/config';
import { Paragraph } from '../layouts/typography';

export const Copyright = () => <Copy>&copy; 2020 Alexander Ketchakmadze</Copy>;

const Copy = styled(Paragraph)`
  color: ${color.note};
  text-align: center;
`;
