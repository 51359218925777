import { MDXProvider } from '@mdx-js/tag';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-mdx/mdx-renderer';
import React from 'react';
import { Article } from '../article/article';
import { Wayfinder } from '../components/navigation/wayfinder';
import { FeaturedTitle } from '../title/title';
import { ornaments } from './ornaments';
import { Paragraph, Quote, typography } from './typography';

// @ts-ignore
export default ({ data, children, ...props }) => {
  const post = data.mdx;
  const { previous, next } = props.pageContext;

  return (
    <MDXProvider components={{ ...typography, ...ornaments }}>
      <Article>
        <Wayfinder next={next} previous={previous} />

        <FeaturedTitle>{post.frontmatter.title}</FeaturedTitle>

        <Paragraph
          dangerouslySetInnerHTML={{ __html: post.frontmatter.description }}
        />

        {post.frontmatter.quote && (
          <Quote dangerouslySetInnerHTML={{ __html: post.frontmatter.quote }} />
        )}

        <MDXRenderer {...props}>{data.mdx.code.body}</MDXRenderer>
      </Article>
    </MDXProvider>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    mdx(fields: { id: { eq: $id } }) {
      code {
        body
        scope
      }
      excerpt
      frontmatter {
        description
        date
        title
        quote
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 2880) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
