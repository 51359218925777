import React from 'react';
import styled from 'styled-components';
import { color, font, fontSize, serif } from '../layouts/config';
import { Paragraph } from '../layouts/typography';

interface Props {
  word: string;
  meaning: string;
}

export const Translate = ({ word, meaning }: Props) => (
  <TranslatedParagraph>
    <strong>{word}</strong> <Meaning>{meaning}</Meaning>
  </TranslatedParagraph>
);

const TranslatedParagraph = styled(Paragraph)`
  font-family: ${serif};
  font-size: ${fontSize.h4};
  margin-bottom: 0;

  & + & {
    margin-top: 0;
  }
`;

const Meaning = styled.span`
  color: ${color.note};
  font-family: ${serif};
  font-style: italic;
  font-weight: ${font.xlight};
`;
