module.exports = [{
      plugin: require('/builds/ketch/portfolio/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://ketch.me"},
    },{
      plugin: require('/builds/ketch/portfolio/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/ketch/portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
