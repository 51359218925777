import React from 'react';
import styled from 'styled-components';
import { media } from './config';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: [React.ReactNode, React.ReactNode];
}

const halfPic = (children: React.ReactNode): React.ReactNode => {
  return React.Children.map(children, ch => {
    if (typeof ch === 'string') {
      return ch;
    }

    if (ch.type === 'source') {
      return React.cloneElement(ch, {
        sizes: '(min-width: 1050px) 50vw, 100vw'
      });
    }

    return React.cloneElement(
      ch,
      ch.props && ch.props.children
        ? {
            children: halfPic(ch.props.children)
          }
        : {}
    );
  });
};

export const TwoUp = ({ children, ...props }: Props) => (
  <Container {...props}>{halfPic(children)}</Container>
);

const Container = styled.div`
  ${media.lg`
    align-items: center;
    display: flex;

    & > blockquote p {
      margin: 0 auto;
      padding: 0;
      width: 100%;
    }

    & > :first-child {
      margin-left: 0;
      margin-right: 1em;
      max-width: none;
      width: 50%;
    }

    & > :last-child {
      margin-left: 1em;
      margin-right: 0;
      max-width: none;
      width: 50%;

      > * {
        text-align: left;
      }
    }

    article & + .gatsby-resp-image-link {
      margin-top: 1.5rem;
    }
  `};
`;
