import * as React from 'react';
import styled from 'styled-components';
import { color, easeOut, easeOut0, fontSize, media } from '../layouts/config';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  hoverSyle?: boolean;
}

// interface State {
//   x: number;
//   y: number;
//   z: number;
// }

export class FeaturedTitle extends React.PureComponent<Props> {
  // private el: React.RefObject<HTMLHeadingElement> = React.createRef();
  // private parent: HTMLDivElement | null = null;
  // // @ts-ignore
  // private parentD: ClientRect | DOMRect;
  // state = { x: 0, y: 0, z: 1 };

  constructor(props: Props) {
    super(props);
  }

  // clamper = (n: number) => clamp(ordNumber)(-50, 50)(n);

  // tracker = (e: React.MouseEvent<HTMLDivElement>) => {
  //   this.setState({
  //     x: this.clamper(
  //       ((e.clientX - this.parentD.left) / this.parentD.width) * 100
  //     ),
  //     y: this.clamper(
  //       ((e.clientY - this.parentD.top) / this.parentD.height) * 100
  //     ),
  //     z: 1.125
  //   });
  // };

  // reset = () => {
  //   this.setState({
  //     x: 0,
  //     y: 0,
  //     z: 1
  //   });
  // };

  // componentDidMount() {
  //   if (this.props.hoverSyle && this.el.current) {
  //     const x = this.el.current.parentElement;
  //     if (x && x.parentElement) {
  //       this.parent = x.parentElement as HTMLDivElement;
  //       this.parentD = x.parentElement.getBoundingClientRect();
  //       x.parentElement.addEventListener('mousemove', this.tracker);
  //       x.parentElement.addEventListener('mouseout', this.reset);
  //     }
  //   }
  // }

  render() {
    const { children } = this.props;
    return (
      <Title
        {...this.props}
        // ref={this.el}
        // x={this.state.x}
        // y={this.state.y}
        // z={this.state.z}
      >
        <Backdrop className="heroTitle">{children}</Backdrop>
      </Title>
    );
  }
}

// interface TitleProps extends Props, State {}
interface TitleProps extends Props {}

const Title = styled.h1.attrs<TitleProps>({
  // style: (props: TitleProps) => ({
  //   transform: `rotateZ(${props.x}deg) rotateX(${
  //     props.y
  //   }deg) translateZ(20px) scaleX(${props.z}) scaleY(${props.z})`
  // })
})`
  color: ${color.stock};
  font-size: ${fontSize.h3};
  font-style: italic;
  font-weight: 900;
  line-height: 1.125;
  margin: 0 auto;
  padding: 0 0.25em;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 1;

  ${media.sm`
    font-size: ${fontSize.h2};
  `};

  ${media.md`
    color: ${color.stock};
    text-shadow: -0.0575em -0.0575em 0 #121212, 0.0575em 0.0575em 0 #56ccaf;
    transition: text-shadow 0.2s ease-out, font-size ${easeOut}, transform ${easeOut};
    font-size: ${fontSize.h25};
  `};

  ${media.xl`
    font-size: ${fontSize.hlg};
  `};
`;

const Backdrop = styled.span`
  background-image: linear-gradient(90deg, #56ccaf 0, #50e3c2);
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 100% 90%;
  padding: 0.0625em 0.25em 0;
  transition: all ${easeOut0};

  ${media.md`
    background-size: 100% 40%;
    background-position: 0 60%;
    padding: 0 0.25em;
  `};
`;
