// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-395-eb-3-cf-3-ce-65-f-4-a-579-c-792-d-1-ab-240-a-2-js": () => import("/builds/ketch/portfolio/.cache/gatsby-mdx/mdx-wrappers-dir/c9247f83bc091bae61d34198d169a45e--scope-hash-395eb3cf3ce65f4a579c792d1ab240a2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-395-eb-3-cf-3-ce-65-f-4-a-579-c-792-d-1-ab-240-a-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-6-ecbbf-5-f-688-fb-5-cb-9-d-4-bfd-7957-e-3381-b-js": () => import("/builds/ketch/portfolio/.cache/gatsby-mdx/mdx-wrappers-dir/c9247f83bc091bae61d34198d169a45e--scope-hash-6ecbbf5f688fb5cb9d4bfd7957e3381b.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-6-ecbbf-5-f-688-fb-5-cb-9-d-4-bfd-7957-e-3381-b-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/builds/ketch/portfolio/.cache/gatsby-mdx/mdx-wrappers-dir/c9247f83bc091bae61d34198d169a45e--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-30-aa-802-d-60718-bc-285-b-45-da-793-f-293-d-9-js": () => import("/builds/ketch/portfolio/.cache/gatsby-mdx/mdx-wrappers-dir/c9247f83bc091bae61d34198d169a45e--scope-hash-30aa802d60718bc285b45da793f293d9.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-30-aa-802-d-60718-bc-285-b-45-da-793-f-293-d-9-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-01-e-1-d-12-d-2-f-66-cd-17119-e-96-d-88-f-6-f-6-a-4-e-js": () => import("/builds/ketch/portfolio/.cache/gatsby-mdx/mdx-wrappers-dir/c9247f83bc091bae61d34198d169a45e--scope-hash-01e1d12d2f66cd17119e96d88f6f6a4e.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-c-9247-f-83-bc-091-bae-61-d-34198-d-169-a-45-e-scope-hash-01-e-1-d-12-d-2-f-66-cd-17119-e-96-d-88-f-6-f-6-a-4-e-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-7-fa-8-aeaeed-6338-f-9-a-2-d-4383-d-824-c-2759-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/builds/ketch/portfolio/.cache/gatsby-mdx/mdx-wrappers-dir/7fa8aeaeed6338f9a2d4383d824c2759--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-7-fa-8-aeaeed-6338-f-9-a-2-d-4383-d-824-c-2759-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-7-fa-8-aeaeed-6338-f-9-a-2-d-4383-d-824-c-2759-scope-hash-395-eb-3-cf-3-ce-65-f-4-a-579-c-792-d-1-ab-240-a-2-js": () => import("/builds/ketch/portfolio/.cache/gatsby-mdx/mdx-wrappers-dir/7fa8aeaeed6338f9a2d4383d824c2759--scope-hash-395eb3cf3ce65f4a579c792d1ab240a2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-7-fa-8-aeaeed-6338-f-9-a-2-d-4383-d-824-c-2759-scope-hash-395-eb-3-cf-3-ce-65-f-4-a-579-c-792-d-1-ab-240-a-2-js" */),
  "component---src-pages-404-tsx": () => import("/builds/ketch/portfolio/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/builds/ketch/portfolio/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("/builds/ketch/portfolio/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-play-tsx": () => import("/builds/ketch/portfolio/src/pages/play.tsx" /* webpackChunkName: "component---src-pages-play-tsx" */)
}

exports.data = () => import("/builds/ketch/portfolio/.cache/data.json")

